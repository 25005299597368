export default {
    "de": {
        "INSURANCE_FLAT": "Administrationspauschale",
        "lessons": "Fahrlektionen",
        "vku": "VKU Kurse",
        "insurance": "Versicherung",
        "app": "Theorie App",
        "voice_status_start": "Start",
        "voice_status_end": "Beendet",
        "voice_status_queue": "Warteschlange",
        "voice_status_taken": "im Gespräch",
        "voice_status_missed": "Verpasst",
        "voice_status_initiated": "Willkommen",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_voicemail": "Sprachnachricht",
        "absences": "Absenzes",
        "exams": "Prüfungen",
        "students": "Schüler",
        "locations": "Standorte",
        "comments": "Kommentare",
        "employment": "Anstellung",
        "course_documents": "Kursdokumente",
        "course_manuals": "Kursanleitungen",
        "All": "Alle",
        "Instructors": "Kursleiter:innen",
        "Kursleiterinnen": "Kursleiter:innen",
        "Teachers": "Fahrlehrer:innen",
        "The {attribute} must contain at least one letter.": "{attribute} muss aus mindestens einem Zeichen bestehen.",
        "The {attribute} must contain at least one number.": "{attribute} muss aus mindestens einer Zahl bestehen.",
        "The {attribute} must contain at least one symbol.": "{attribute} muss aus mindestens einem Sonderzeichen bestehen.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} muss aus mindestens einem Gross- und einem Kleinbuchstaben bestehen.",
        "{attribute} is keine gültige Sprache.": "{attribute} ist keine gültige Sprache.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Das {attribute} ist bereits in einem Datenleck aufgetaucht. Bitte wähle ein anderes {attribute}.",
        "Anmelden": "Anmelden",
        "E-Mail": "E-Mail",
        "Passwort": "Passwort",
        "Passwort vergessen?": "Passwort vergessen?",
        "Eine Woche lang angemeldet bleiben": "Eine Woche lang angemeldet bleiben",
        "Willkommen bei": "Willkommen bei",
        "Passwort zurücksetzen": "Passwort zurücksetzen",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.",
        "Zurück zur Anmeldung": "Zurück zur Anmeldung",
        "Link anfordern": "Link anfordern",
        "zurück": "zurück",
        "Liste exportieren": "Liste exportieren",
        "Anmeldungen": "Anmeldungen",
        "Abmeldungen": "Abmeldungen",
        "Vorname": "Vorname",
        "Nachname": "Nachname",
        "Kurssprache": "Kurssprache",
        "Beschreibung": "Beschreibung",
        "Link ist aktiv": "Link ist aktiv",
        "Kurs freigeben": "Kurs freigeben",
        "Es existieren keine Daten.": "Es existieren keine Daten.",
        "Willkommen": "Willkommen",
        "Teilnehmende": "Teilnehmende",
        "Sortieren nach": "Sortieren nach",
        "Datum": "Datum",
        "Adresse": "Adresse",
        "Gesamte Liste freigeben": "Gesamte Liste freigeben",
        "Kursgruppe freigeben": "Kursgruppe freigeben",
        "Plätze frei": "Plätze frei",
        "ausgebucht": "ausgebucht",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Vielen Dank! Wir haben Ihre Angaben erhalten.",
        "Weitere Person anmelden": "Weitere Person anmelden",
        "An-/Abmeldung": "An-/Abmeldung",
        "Ja, ich nehme teil": "Ja, ich nehme teil",
        "Nein, ich bin verhindert": "Nein, ich bin verhindert",
        "Ich akzeptiere die": "Ich akzeptiere die",
        "allgemeinen Geschäftsbedingungen": "allgemeinen Geschäftsbedingungen",
        "Die": "Die",
        "Datenschutzerklärung": "Datenschutzerklärung",
        "habe ich gelesen und verstanden.": "habe ich gelesen und verstanden.",
        "Absenden": "Absenden",
        "Sie müssen die AGB akzeptieren!": "Sie müssen die AGB akzeptieren!",
        "Fragen oder Probleme?": "Fragen oder Probleme?",
        "Ihre Ansprechperson:": "Ihre Ansprechperson:",
        "Hallo": "Hallo",
        "Anmeldebestätigung": "Anmeldebestätigung",
        "Erste Hilfe Kurs": "Erste Hilfe Kurs",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos",
        "Liebe Grüsse": "Liebe Grüsse",
        "Dein Sanio-Team": "Dein Sanio-Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Umbuchungsbestätigung",
        "Terminerinnerung": "Terminerinnerung",
        "Mitarbeitende": "Mitarbeitende",
        "Deutsch": "Deutsch",
        "Französisch": "Französisch",
        "Italienisch": "Italienisch",
        "Englisch": "Englisch",
        "Spanisch": "Spanisch",
        "Portugiesisch": "Portugiesisch",
        "Kurs wechseln": "Kurs wechseln",
        "Teilnehmende abmelden": "Teilnehmende abmelden",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "Den/die Teilnehmende wirklich vom Kurs abmelden?",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?",
        "Abbrechen": "Abbrechen",
        "Abmelden": "Abmelden",
        "{amount} Teilnehmende": "{amount} Teilnehmende",
        "Kurse": "Kurse",
        "Impersonation beenden": "Impersonation beenden",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Die Datei {fileName} wurde {statusHint} importiert.",
        "teilweise": "teilweise",
        "erfolgreich": "erfolgreich",
        "Zeile": "Zeile",
        "Spalte": "Spalte",
        "Fehlermeldung": "Fehlermeldung",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:",
        "Kontaktdaten": "Kontaktdaten",
        "Ähnliche TN": "Ähnliche TN",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.",
        "Teilnehmerübersicht öffnen": "Teilnehmerübersicht öffnen",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.",
        "Email": "E-Mail",
        "Handynummer": "Handynummer",
        "certificates.not-yet-known": "Noch nicht bekannt",
        "certificates.sanio": "Kursbestätigung Sanio",
        "certificates.src": "SRC Zertifikat",
        "Kursübersicht": "Kursübersicht",
        "Zurück zum Login": "Zurück zum Login",
        "Passwort bestätigen": "Passwort bestätigen",
        "Dashboard": "Dashboard",
        "In welchen Kurs soll {participantName} verschoben werden?": "In welchen Kurs soll {participantName} verschoben werden?",
        "Es gibt keine anderen Kurse.": "Es gibt keine anderen Kurse.",
        "Mehr anzeigen": "Mehr anzeigen",
        "{participantName} in Kurs {courseName} verschieben?": "{participantName} in Kurs {courseName} verschieben?",
        "Zum Kurs": "Zum Kurs",
        "Umbuchungsbestätigung senden": "Umbuchungsbestätigung senden",
        "Zurück": "Zurück",
        "Verschieben": "Verschieben",
        "Willkommen {companyName} 👋": "Willkommen {companyName} 👋",
        "Diese Anmeldemaske wurde deaktiviert.": "Diese Anmeldemaske wurde deaktiviert.",
        "errors.4xx.title": "Unerwarteter Fehler",
        "errors.4xx.head": "Uups...",
        "errors.4xx.hint": "Ein Fehler ist aufgetreten. Ihre Anfrage konnte nicht verarbeitet werden. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.5xx.title": "Interner Serverfehler",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehen Sie zurück zur Startseite oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.401.title": "Zugriff verweigert",
        "errors.401.head": "Oops…",
        "errors.401.hint": "Sie sind nicht berechtigt, auf diese Seite zuzugreifen. Bitte melden Sie sich an und versuchen Sie es erneut.",
        "errors.403.title": "Zugriff verweigert",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Zugriff verweigert. Sie haben keine Berechtigung, diese Seite zu besuchen. Bitte wenden Sie sich an uns, wenn Sie glauben, dass dies ein Fehler ist.",
        "errors.404.title": "Seite nicht gefunden",
        "errors.404.head": "Uups...",
        "errors.404.hint": "Die von Ihnen aufgerufene Seite existiert nicht. Gehen Sie zurück oder klicken Sie auf den Button um auf die Startseite zu gelangen.",
        "errors.500.title": "Interner Serverfehler",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehen Sie zurück zur Startseite oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.503.title": "Wartungsmodus",
        "errors.503.head": "Einen Moment bitte...",
        "errors.503.hint": "Wir führen derzeit Wartungsarbeiten durch, um Verbesserungen und Fehlerbehebungen für eine bessere Nutzererfahrung vorzunehmen. Bitte versuchen Sie es in Kürze erneut. Vielen Dank für Ihr Verständnis.",
        "errors.503.hint2": "In dringenden Fällen erreichen Sie uns per E-Mail unter <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> oder telefonisch unter <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "Die Anfrage hat zu lange gedauert. Bitte versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.back-to-start": "Zur Startseite",
        "Fehler beim Laden der Kurse": "Fehler beim Laden der Kurse",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Du wurdest in den Kurs «{courseName}» umgebucht.",
        "Die aktuellen Infos lauten:": "Hier die neuen Infos:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:",
        "oder": "oder",
        "Kursort:": "Kursort:",
        "Kursdaten": "Kursdaten",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Bald ist es soweit und der Kurs «{courseName}» findet statt.",
        "Hier nochmals die wichtigsten Infos:": "Hier nochmals die wichtigsten Infos:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉",
        "Kurs-ID": "Kurs-ID",
        "Zum Kalender hinzufügen": "Zum Kalender hinzufügen",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.",
        "Die E-Mail-Adresse ist ungültig.": "Die E-Mail-Adresse ist ungültig",
        "Die Handynummer ist ungültig.": "Die Handynummer ist ungültig.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Mit dieser E-Mail kannst du dich leider nicht anmelden.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.",
        "Der Code ist ungültig.": "Der Code ist ungültig.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Sie müssen die AGBs akzeptieren um fortfahren zu können.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Bitte geben Sie eine gültige Handynummer ein.",
        "Text wurde in die Zwischenablage kopiert": "Text wurde in die Zwischenablage kopiert",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text konnte nicht in die Zwischenablage kopiert werden",
        "Der Katalog wurde erstellt.": "Der Katalog wurde erstellt.",
        "Der Katalog wurde aktualisiert.": "Der Katalog wurde aktualisiert.",
        "Der Katalog wurde entfernt.": "Der Katalog wurde entfernt.",
        "Das Thema wurde erstellt.": "Das Thema wurde erstellt.",
        "Das Thema wurde aktualisiert.": "Das Thema wurde aktualisiert.",
        "Das Thema wurde entfernt.": "Das Thema wurde entfernt.",
        "Der Kurs wurde dupliziert 🎉": "Der Kurs wurde dupliziert 🎉",
        "Kurs": "Kurs",
        "Story wurde erfolgreich kopiert 🎉": "Story wurde erfolgreich kopiert 🎉",
        "Eine Variante|{count} Varianten": "Eine Variante|{count} Varianten",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.",
        "Geschlecht": "Geschlecht",
        "Männlich": "Männlich",
        "Weiblich": "Weiblich",
        "Divers": "Divers",
        "Projektstandort": "Projektstandort",
        "Wohnkanton": "Wohnkanton",
        "Sanio Logo": "Sanio Logo",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.",
        "hier": "hier",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.",
        "Dein Sanio Team": "Dein Sanio Team",
        "Hallo {name} 👋": "Hallo {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Hier sind nochmals die wichtigsten Details:",
        "Teilnehmer:innen": "Teilnehmer:innen",
        "Name": "Name",
        "Letzter Kurs": "Letzter Kurs",
        "Nächster Kurs": "Nächster Kurs",
        "Hinzufügen": "Hinzufügen",
        "Exportieren": "Exportieren",
        "An Kurs angemeldet": "An Kurs angemeldet",
        "Keinem Kurs zugeordnet": "Keinem Kurs zugeordnet",
        "Suchbegriff": "Suchbegriff",
        "Alle": "Alle",
        "Ungruppierte Kurse": "Ungruppierte Kurse",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus."
    },
    "en": {
        "Anmelden": "Log in",
        "E-Mail": "Email",
        "Passwort": "Password",
        "Passwort vergessen?": "Forgot your password?",
        "Eine Woche lang angemeldet bleiben": "Stay signed in for a week",
        "Willkommen bei": "Welcome to",
        "Passwort zurücksetzen": "Reset password",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "You'll receive a link by email to reset your password. Please enter the email address you used to sign in to My Sanio.",
        "Zurück zur Anmeldung": "Return to sign in",
        "Link anfordern": "Request link",
        "zurück": "back",
        "Liste exportieren": "Export list",
        "Anmeldungen": "Registrations",
        "Abmeldungen": "Cancellations",
        "Vorname": "First name",
        "Nachname": "Last name",
        "Kurssprache": "Course language",
        "Beschreibung": "Description",
        "Link ist aktiv": "Link is active",
        "Kurs freigeben": "Share course",
        "Es existieren keine Daten.": "No existing data.",
        "Willkommen": "Welcome",
        "Teilnehmende": "Participants",
        "Sortieren nach": "Sort by",
        "Datum": "Date",
        "Adresse": "Address",
        "Gesamte Liste freigeben": "Share entire list",
        "Kursgruppe freigeben": "Share course group",
        "Plätze frei": "Remaining slots",
        "ausgebucht": "fully booked",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Thank you very much! We have received your details.",
        "Weitere Person anmelden": "Register additional person",
        "An-/Abmeldung": "Registration/cancellation",
        "Ja, ich nehme teil": "Yes, I will participate",
        "Nein, ich bin verhindert": "No, I'm unable to attend",
        "Ich akzeptiere die": "I accept the",
        "allgemeinen Geschäftsbedingungen": "Terms and Conditions",
        "Die": "The",
        "Datenschutzerklärung": "Privacy policy",
        "habe ich gelesen und verstanden.": "I have read and understood.",
        "Absenden": "Send",
        "Sie müssen die AGB akzeptieren!": "You must accept the terms!",
        "Fragen oder Probleme?": "Questions or problems?",
        "Ihre Ansprechperson:": "Your contact person:",
        "Geburtsdatum": "Date of birth",
        "Geburtsdatum (TT.MM.JJJJ)": "Date of birth (dd.mm.yyyy)",
        "Hallo": "Hello",
        "Anmeldebestätigung": "Registration Confirmation",
        "Erste Hilfe Kurs": "First Aid Course",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Thank you for your registration for the First Aid Course. Here are the most important details again",
        "Liebe Grüsse": "Best regards",
        "Dein Sanio-Team": "Your Sanio Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Booking confirmation",
        "Terminerinnerung": "Appointment reminder",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "The time has come, and the first aid course is taking place soon. Here are the most important details again:",
        "Zurück zum Login": "Back to login",
        "Passwort bestätigen": "Confirm password",
        "Mitarbeiter:innen": "Employees",
        "Deutsch": "German",
        "Französisch": "French",
        "Italienisch": "Italian",
        "Englisch": "English",
        "Spanisch": "Spanish",
        "Portugiesisch": "Portuguese",
        "Kurse": "Courses",
        "Impersonation beenden": "Stop impersonation",
        "INSURANCE_FLAT": "Administration fee",
        "lessons": "Driving lessons",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "Do you really want to deregister the participant from the course?",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "Do you really want to deregister the participant from the course?",
        "Teachers": "Driving instructors",
        "Teilnehmende abmelden": "Deregister participants",
        "Kurs wechseln": "Change course",
        "Abbrechen": "Cancel",
        "Abmelden": "Log out",
        "Kursleiterinnen": "Instructors",
        "absences": "Absences",
        "All": "All",
        "app": "Theory app",
        "certificates.not-yet-known": "Not yet known",
        "certificates.sanio": "Course confirmation Sanio",
        "certificates.src": "SRC certificate",
        "comments": "Comments",
        "course_documents": "Course documents",
        "course_manuals": "Course instructions",
        "Die Datei {fileName} wurde {statusHint} importiert.": "The file {fileName} was imported {statusHint}.",
        "employment": "Employment",
        "erfolgreich": "Successful",
        "exams": "Examinations",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "If you use Excel for the import, make sure that columns such as the date of birth are formatted as text.",
        "Fehlermeldung": "Error message",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "The following lines could not be imported due to invalid (<span class=\"text-red\">marked in red</span>) data:",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "The following lines were nevertheless imported due to unclear assignment.",
        "Handynummer": "Cell phone number",
        "Instructors": "Instructors",
        "insurance": "Insurance",
        "Kontaktdaten": "Contact details",
        "locations": "Locations",
        "Mitarbeitende": "Employees",
        "Spalte": "Column",
        "students": "Pupils",
        "Teilnehmerübersicht öffnen": "Open participant overview",
        "teilweise": "Partly",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The {attribute} has already appeared in a data leak. Please choose another {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} must consist of at least one character.",
        "The {attribute} must contain at least one number.": "{attribute} must consist of at least one number.",
        "The {attribute} must contain at least one symbol.": "{attribute} must consist of at least one special character.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} must consist of at least one uppercase and one lowercase letter.",
        "vku": "VKU courses",
        "voice_status_end": "Finished",
        "voice_status_missed": "Missed",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_queue": "Queue",
        "voice_status_start": "Start",
        "voice_status_taken": "in conversation",
        "voice_status_voicemail": "Voice message",
        "Zeile": "Line",
        "{amount} Teilnehmende": "{amount} Participants",
        "Ähnliche TN": "Similar TN",
        "Email": "Email",
        "voice_status_initiated": "Welcome",
        "Dashboard": "Dashboard",
        "Es gibt keine anderen Kurse.": "There are no other courses.",
        "Mehr anzeigen": "Show more",
        "{participantName} in Kurs {courseName} verschieben?": "Move {participantName} to course {courseName}?",
        "Zum Kurs": "To the course",
        "Umbuchungsbestätigung senden": "Send rebooking confirmation",
        "Zurück": "Back",
        "Verschieben": "Move",
        "Willkommen {companyName} 👋": "Welcome {companyName} 👋",
        "Kursübersicht": "Course overview",
        "In welchen Kurs soll {participantName} verschoben werden?": "Which course should {participantName} be moved to?",
        "Diese Anmeldemaske wurde deaktiviert.": "This login screen has been deactivated.",
        "errors.4xx.title": "Unexpected error",
        "errors.4xx.head": "Oops...",
        "errors.5xx.head": "Ou ou...",
        "errors.4xx.hint": "An error has occurred. Your request could not be processed. Please check your entries or try again later. The error has been reported to us.",
        "errors.5xx.title": "Internal server error",
        "errors.5xx.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.401.title": "Access denied",
        "errors.401.head": "Oops…",
        "errors.401.hint": "You are not authorized to access this page. Please log in and try again.",
        "errors.403.title": "Access denied",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Access denied. You do not have permission to visit this page. Please contact us if you believe this is an error.",
        "errors.404.title": "Page not found",
        "errors.404.head": "Oops...",
        "errors.404.hint": "The page you have called up does not exist. Go back or click on the button to return to the start page.",
        "errors.500.title": "Internal server error",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.503.title": "Maintenance mode",
        "errors.503.head": "One moment please...",
        "errors.503.hint": "We are currently performing maintenance to make improvements and bug fixes for a better user experience. Please try again shortly. Thank you for your understanding.",
        "errors.503.hint2": "In urgent cases, you can reach us by e-mail at <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> or by telephone at <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "The request took too long. Please try again later. The error has been reported to us.",
        "errors.back-to-start": "To the homepage",
        "{attribute} is keine gültige Sprache.": "{attribute} is not a valid language.",
        "Fehler beim Laden der Kurse": "Error loading the courses",
        "oder": "or",
        "Zum Kalender hinzufügen": "Add to calendar",
        "Der Code ist ungültig.": "The code is invalid.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "You must accept the terms and conditions in order to continue.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Please enter a valid cell phone number.",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "You have been rebooked into the course \"{courseName}\".",
        "Die aktuellen Infos lauten:": "Here is the new information:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "If you have any questions, please contact {name} directly:",
        "Kursort:": "Course location:",
        "Kursdaten": "Course dates",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Soon the time will come and the course \"{courseName}\" will take place.",
        "Hier nochmals die wichtigsten Infos:": "Here is the most important information again:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "You have been successfully enrolled on the course \"{courseName}\" 🎉",
        "Kurs-ID": "Course ID",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "No e-mail address or cell phone number was provided.",
        "Die E-Mail-Adresse ist ungültig.": "The e-mail address is invalid",
        "Die Handynummer ist ungültig.": "The cell phone number is invalid.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this telephone number.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Thank you for your registration for the First Aid Course. Here are the most important details again",
        "Text wurde in die Zwischenablage kopiert": "Text has been copied to the clipboard",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text could not be copied to the clipboard",
        "Der Katalog wurde erstellt.": "The catalog was created.",
        "Der Katalog wurde aktualisiert.": "The catalog has been updated.",
        "Der Katalog wurde entfernt.": "The catalog has been removed.",
        "Das Thema wurde erstellt.": "The topic was created.",
        "Das Thema wurde aktualisiert.": "The topic has been updated.",
        "Das Thema wurde entfernt.": "The topic has been removed.",
        "Der Kurs wurde dupliziert 🎉": "The course has been duplicated 🎉",
        "Kurs": "Course",
        "Story wurde erfolgreich kopiert 🎉": "Story was successfully copied 🎉",
        "Eine Variante|{count} Varianten": "One variant|{count} Variants",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "The eLearning area is not available for this customer.",
        "Geschlecht": "Gender",
        "Männlich": "male",
        "Keinem Kurs zugeordnet": "Not assigned to a course",
        "Suchbegriff": "Search term",
        "Weiblich": "female",
        "Divers": "other",
        "Projektstandort": "Project location",
        "Wohnkanton": "Canton of residence",
        "Sanio Logo": "Sanio logo",
        "hier": "here",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Click here for our <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">general terms and conditions</a> and our <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">privacy policy</a>.",
        "Hallo {name} 👋": "Hello {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Here are the most important details again:",
        "Teilnehmer:innen": "Participants",
        "Name": "Name",
        "Letzter Kurs": "Last course",
        "Nächster Kurs": "Next course",
        "Hinzufügen": "Add",
        "Exportieren": "Export",
        "An Kurs angemeldet": "Registered for a course",
        "Dein Sanio Team": "Your Sanio Team",
        "Alle": "All",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "If you no longer wish to receive emails from this list, you can unsubscribe at <a href=\"{unsubscribeListUrl}\" target=\"_blank\">here</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Do you no longer wish to receive offers from us? Then you can unsubscribe at <a href=\"{unsubscribeUrl}\" target=\"_blank\">here</a>."
    },
    "es": {
        "Anmelden": "Regístrese en",
        "E-Mail": "correo electrónico",
        "Passwort": "Contraseña",
        "Passwort vergessen?": "¿Ha olvidado su contraseña?",
        "Eine Woche lang angemeldet bleiben": "Permanecer conectado durante una semana",
        "Willkommen bei": "Bienvenido a",
        "Passwort zurücksetzen": "Restablecer contraseña",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Recibirás un enlace por correo electrónico para restablecer tu contraseña. Introduce la dirección de correo electrónico que utilizaste para iniciar sesión en Mi Sanio.",
        "Zurück zur Anmeldung": "Volver al inicio de sesión",
        "Link anfordern": "Solicitar enlace",
        "zurück": "volver",
        "Liste exportieren": "Exportar lista",
        "Anmeldungen": "Inscripciones",
        "Abmeldungen": "Anulaciones",
        "Vorname": "Nombre",
        "Nachname": "Apellido",
        "Kurssprache": "Idioma del curso",
        "Beschreibung": "Descripción",
        "Link ist aktiv": "Enlace activo",
        "Kurs freigeben": "Compartir curso",
        "Es existieren keine Daten": "No existen datos.",
        "Willkommen": "Bienvenido",
        "Teilnehmende": "Participantes",
        "Sortieren nach": "Ordenar por",
        "Datum": "Fecha",
        "Adresse": "Dirección",
        "Gesamte Liste freigeben": "Compartir toda la lista",
        "Kursgruppe freigeben": "Compartir grupo de cursos",
        "Plätze frei": "Franjas horarias restantes",
        "ausgebucht": "completo",
        "Vielen Dank! Wir haben Ihre Angaben erhalten": "Muchas gracias. Hemos recibido sus datos.",
        "Weitere Person anmelden": "Inscribir a una persona adicional",
        "An-/Abmeldung": "Inscripción/anulación",
        "Ja, ich nehme teil": "Sí, participaré",
        "Nein, ich bin verhindert": "No, no puedo asistir",
        "Ich akzeptiere die": "Acepto la",
        "allgemeinen Geschäftsbedingungen": "Condiciones generales",
        "Die": "En",
        "Datenschutzerklärung": "Política de privacidad",
        "habe ich gelesen und verstanden": "He leído y comprendido.",
        "Absenden": "Enviar",
        "Sie müssen die AGB akzeptieren!": "Debes aceptar las condiciones.",
        "Fragen oder Probleme?": "¿Preguntas o problemas?",
        "Ihre Ansprechperson:": "Su persona de contacto:",
        "Geburtsdatum": "Fecha de nacimiento",
        "Hallo": "Hola",
        "Anmeldebestätigung": "Confirmación de Registro",
        "Erste Hilfe Kurs": "Curso de Primeros Auxilios",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Gracias por tu inscripción al curso de primeros auxilios. Aquí tienes nuevamente la información más importante",
        "Liebe Grüsse": "Saludos cordiales",
        "Dein Sanio-Team": "Tu equipo Sanio",
        "Copyright": "Derechos de Autor",
        "Umbuchungsbestätigung": "Confirmación de cambio de reserva",
        "Terminerinnerung": "Recordatorio de cita",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Pronto llegará el momento y el curso de primeros auxilios tendrá lugar. Aquí están nuevamente los detalles más importantes:",
        "Deutsch": "Alemán",
        "Französisch": "Francés",
        "Italienisch": "Italiano",
        "Englisch": "Inglés",
        "Spanisch": "Español",
        "Portugiesisch": "Portugués",
        "Kurse": "Cursos",
        "Impersonation beenden": "Finalizar suplantación",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "¿Realmente está dando de baja al participante del curso?",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "¿Realmente desea dar de baja al participante del curso?",
        "Teilnehmende abmelden": "Cancelación de participantes",
        "exams": "Exámenes",
        "comments": "Comentarios",
        "employment": "Empleo",
        "course_documents": "Documentos del curso",
        "course_manuals": "Instrucciones del curso",
        "All": "Todos",
        "Kursübersicht": "Resumen del curso",
        "Zurück zum Login": "Volver al inicio de sesión",
        "Passwort bestätigen": "Confirmar contraseña",
        "Handynummer": "Número de teléfono móvil",
        "certificates.not-yet-known": "Aún no se conoce",
        "certificates.sanio": "Confirmación del curso Sanio",
        "certificates.src": "Certificado SRC",
        "Dashboard": "Cuadro de mandos",
        "In welchen Kurs soll {participantName} verschoben werden?": "¿A qué curso debe trasladarse {participantName}?",
        "Es gibt keine anderen Kurse.": "No hay otros cursos.",
        "Mehr anzeigen": "Ver más",
        "{participantName} in Kurs {courseName} verschieben?": "¿Mover {participantName} al curso {courseName}?",
        "app": "Teoría de la aplicación",
        "Zum Kurs": "Al curso",
        "Umbuchungsbestätigung senden": "Enviar confirmación de nueva reserva",
        "Zurück": "Volver",
        "Verschieben": "Mover",
        "Willkommen {companyName} 👋": "Bienvenido {companyName} 👋",
        "absences": "Ausencias",
        "{amount} Teilnehmende": "{amount} Participantes",
        "Zeile": "Línea",
        "Ähnliche TN": "TN similares",
        "Abbrechen": "Cancelar",
        "Abmelden": "Cerrar sesión",
        "Die Datei {fileName} wurde {statusHint} importiert.": "El archivo {fileName} fue importado {statusHint}.",
        "erfolgreich": "Éxito",
        "Es existieren keine Daten.": "No existen datos.",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Si utiliza Excel para la importación, asegúrese de que columnas como la fecha de nacimiento estén formateadas como texto.",
        "Fehlermeldung": "Mensaje de error",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "<span class=\"text-red\"></span>Las siguientes líneas no se han podido importar debido a datos no válidos ( marcados en rojo ):",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "No obstante, las siguientes líneas se importaron debido a una asignación poco clara.",
        "habe ich gelesen und verstanden.": "He leído y comprendido.",
        "Instructors": "Instructores",
        "insurance": "seguro",
        "INSURANCE_FLAT": "Tasa administrativa",
        "Kontaktdaten": "Datos de contacto",
        "Kurs wechseln": "Cambiar de rumbo",
        "Kursleiterinnen": "Instructores",
        "lessons": "Clases de conducir",
        "locations": "Ubicaciones",
        "Mitarbeitende": "Empleados",
        "Spalte": "Columna",
        "students": "Alumnos",
        "Teachers": "Profesores de autoescuela",
        "Teilnehmerübersicht öffnen": "Visión general de los participantes abiertos",
        "teilweise": "parcialmente",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "El {attribute} ya ha aparecido en una filtración de datos. Por favor, seleccione otro {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} debe constar de al menos un carácter.",
        "The {attribute} must contain at least one number.": "{attribute} debe constar de al menos un número.",
        "The {attribute} must contain at least one symbol.": "{attribute} debe constar de al menos un carácter especial.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} debe constar de al menos una letra mayúscula y una minúscula.",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Muchas gracias. Hemos recibido sus datos.",
        "vku": "Cursos de la VKU",
        "voice_status_end": "Terminado",
        "voice_status_missed": "Falta",
        "voice_status_out-of-office": "Fuera de la oficina",
        "voice_status_queue": "Cola",
        "voice_status_start": "Inicio",
        "voice_status_taken": "en diálogo",
        "voice_status_voicemail": "Mensaje de voz",
        "Email": "correo electrónico",
        "voice_status_initiated": "Bienvenido",
        "Diese Anmeldemaske wurde deaktiviert.": "Esta pantalla de acceso ha sido desactivada.",
        "errors.4xx.title": "Error inesperado",
        "errors.4xx.head": "Uy...",
        "errors.4xx.hint": "Se ha producido un error. No se ha podido procesar su consulta. Por favor, compruebe sus datos o inténtelo de nuevo más tarde. Se nos ha comunicado el error.",
        "errors.5xx.title": "Error interno del servidor",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Se ha producido un error inesperado. Vuelva a la página de inicio o inténtelo más tarde. Se nos ha comunicado el error.",
        "errors.401.title": "Acceso denegado",
        "errors.401.head": "Uy…",
        "errors.401.hint": "No está autorizado a acceder a esta página. Inicie sesión e inténtelo de nuevo.",
        "errors.403.title": "Acceso denegado",
        "errors.403.head": "Uy…",
        "errors.403.hint": "Acceso denegado. No está autorizado a visitar esta página. Póngase en contacto con nosotros si cree que se trata de un error.",
        "errors.404.title": "Página no encontrada",
        "errors.404.head": "Uy...",
        "errors.500.title": "Error interno del servidor",
        "errors.500.head": "Ou ou...",
        "errors.503.hint2": "<a href=\"mailto:mail@sanio.ch\"></a> <a href=\"tel:+41315212409\"></a>En casos urgentes, puede ponerse en contacto con nosotros por correo electrónico en mail@sanio.ch o por teléfono en el 031 521 24 09 .",
        "errors.504.title": "Tiempo de espera",
        "errors.504.head": "Tiempo muerto…",
        "errors.504.hint": "La consulta ha tardado demasiado. Vuelva a intentarlo más tarde. Se nos ha comunicado el error.",
        "errors.back-to-start": "A la página de inicio",
        "errors.404.hint": "La página que ha llamado no existe. Retroceda o haga clic en el botón para volver a la página de inicio.",
        "errors.500.hint": "Se ha producido un error inesperado. Vuelva a la página de inicio o inténtelo más tarde. Se nos ha comunicado el error.",
        "errors.503.title": "Modo de mantenimiento",
        "errors.503.head": "Un momento por favor...",
        "errors.503.hint": "Actualmente estamos realizando tareas de mantenimiento para realizar mejoras y corregir errores para una mejor experiencia de usuario. Vuelva a intentarlo en breve. Gracias por su comprensión.",
        "{attribute} is keine gültige Sprache.": "{attribute} no es un idioma válido.",
        "Fehler beim Laden der Kurse": "Error al cargar los cursos",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Ha sido reinscrito en el curso \"{courseName}\".",
        "oder": "o",
        "Kursdaten": "Fechas de los cursos",
        "Hier nochmals die wichtigsten Infos:": "He aquí de nuevo la información más importante:",
        "Die aktuellen Infos lauten:": "He aquí la nueva información:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Si tiene alguna pregunta, póngase en contacto directamente con {name}:",
        "Kursort:": "Lugar del curso:",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Pronto llegará el momento y tendrá lugar el curso \"{courseName}\".",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Te has matriculado correctamente en el curso \"{courseName}\" 🎉.",
        "Kurs-ID": "ID del curso",
        "Zum Kalender hinzufügen": "Añadir al calendario",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "No se facilitó dirección de correo electrónico ni número de teléfono móvil.",
        "Die E-Mail-Adresse ist ungültig.": "La dirección de correo electrónico no es válida",
        "Die Handynummer ist ungültig.": "El número de teléfono móvil no es válido.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Lamentablemente, no puede registrarse con este correo electrónico.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Lamentablemente, no puede registrarse con este número de teléfono.",
        "Der Code ist ungültig.": "El código no es válido.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Debe aceptar los términos y condiciones para continuar.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Introduzca un número de teléfono móvil válido.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Gracias por tu inscripción al curso de primeros auxilios. Aquí tienes nuevamente la información más importante",
        "Text wurde in die Zwischenablage kopiert": "El texto se ha copiado en el portapapeles",
        "Text konnte nicht in die Zwischenablage kopiert werden": "No se ha podido copiar el texto en el portapapeles",
        "Der Katalog wurde erstellt.": "Se creó el catálogo.",
        "Der Katalog wurde aktualisiert.": "Se ha actualizado el catálogo.",
        "Der Katalog wurde entfernt.": "Se ha eliminado el catálogo.",
        "Das Thema wurde erstellt.": "Se ha creado el tema.",
        "Das Thema wurde aktualisiert.": "Se ha actualizado el tema.",
        "Das Thema wurde entfernt.": "El tema ha sido eliminado.",
        "Der Kurs wurde dupliziert 🎉": "El curso ha sido duplicado 🎉",
        "Kurs": "Curso",
        "Story wurde erfolgreich kopiert 🎉": "La historia se ha copiado correctamente 🎉",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "El área de eLearning no está disponible para este cliente.",
        "Eine Variante|{count} Varianten": "Una variante|{count} Variantes",
        "Geschlecht": "Género",
        "Männlich": "masculinos",
        "Weiblich": "femeninos",
        "Divers": "otro",
        "Projektstandort": "Ubicación del proyecto",
        "Wohnkanton": "Cantón de residencia",
        "Sanio Logo": "Logotipo de Sanio",
        "hier": "aquí",
        "Hallo {name} 👋": "Hola {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "He aquí de nuevo los detalles más importantes:",
        "Teilnehmer:innen": "Participantes",
        "Name": "Nombre",
        "Letzter Kurs": "Último curso",
        "Nächster Kurs": "Próximo curso",
        "Hinzufügen": "Añadir",
        "Exportieren": "Exportar",
        "An Kurs angemeldet": "Inscrito en un curso",
        "Keinem Kurs zugeordnet": "No asignado a un curso",
        "Suchbegriff": "Término de búsqueda",
        "Dein Sanio Team": "Tu equipo Sanio",
        "Alle": "Todos",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Aquí puedes encontrar nuestros <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">términos y condiciones generales</a> y nuestra <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">política de privacidad</a>.",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si no deseas recibir más correos electrónicos de esta lista, puedes darte de baja <a href=\"{unsubscribeListUrl}\" target=\"_blank\">aquí</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "¿Ya no quieres recibir ofertas de nuestra parte? Puedes darte de baja <a href=\"{unsubscribeUrl}\" target=\"_blank\">aquí</a>."
    },
    "fr": {
        "Anmelden": "S'inscrire",
        "E-Mail": "E-mail",
        "Passwort": "Mot de passe",
        "Passwort vergessen?": "Mot de passe oublié ?",
        "Eine Woche lang angemeldet bleiben": "Rester connecté pendant une semaine",
        "Willkommen bei": "Bienvenue chez",
        "Passwort zurücksetzen": "Réinitialiser le mot de passe",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe. Veuillez saisir l'adresse e-mail que vous utilisez pour vous connecter à My Sanio.",
        "Zurück zur Anmeldung": "Retour à l'inscription",
        "Link anfordern": "Demander un lien",
        "zurück": "retour",
        "Liste exportieren": "Exporter la liste",
        "Anmeldungen": "Inscriptions",
        "Abmeldungen": "Désinscriptions",
        "Vorname": "Prénom",
        "Nachname": "Nom",
        "Kurssprache": "Langue du cours",
        "Beschreibung": "Description",
        "Link ist aktiv": "Le lien est actif",
        "Kurs freigeben": "Valider le cours",
        "Es existieren keine Daten.": "Il n'existe pas de données.",
        "Willkommen": "Bienvenue",
        "Teilnehmende": "Participants",
        "Sortieren nach": "Trier par",
        "Datum": "Date",
        "Gesamte Liste freigeben": "Valider toute la liste",
        "Kursgruppe freigeben": "Valider le groupe de cours",
        "Plätze frei": "Places libres",
        "ausgebucht": "complet",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Merci beaucoup ! Nous avons bien reçu vos informations.",
        "Weitere Person anmelden": "Inscrire une autre personne",
        "An-/Abmeldung": "Inscription/désinscription",
        "Ja, ich nehme teil": "Oui, je participe",
        "Nein, ich bin verhindert": "Non, j'ai un empêchement",
        "Ich akzeptiere die": "J'accepte les",
        "allgemeinen Geschäftsbedingungen": "Conditions générales",
        "Die": "La",
        "Datenschutzerklärung": "Déclaration de confidentialité",
        "habe ich gelesen und verstanden.": "j'ai lu et compris.",
        "Absenden": "Envoyer",
        "Sie müssen die AGB akzeptieren!": "Vous devez accepter les CGV !",
        "Fragen oder Probleme?": "Des questions ou des problèmes ?",
        "Ihre Ansprechperson:": "Votre personne de contact :",
        "Geburtsdatum": "Date de naissance",
        "Geburtsdatum (TT.MM.JJJJ)": "Date de naissance (jj.mm.aaaa)",
        "Hallo": "Salut",
        "Anmeldebestätigung": "Confirmation d'inscription",
        "Erste Hilfe Kurs": "Cours de premiers secours",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Merci beaucoup pour ton inscription au cours de premiers secours. Voici encore une fois les informations les plus importantes",
        "Liebe Grüsse": "Cordialement",
        "Dein Sanio-Team": "Votre équipe Sanio",
        "Copyright": "Droits d'auteur",
        "Umbuchungsbestätigung": "Confirmation de modification de réservation",
        "Terminerinnerung": "Rappel de rendez-vous",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Le moment est venu et le cours de premiers secours aura lieu bientôt. Voici à nouveau les informations les plus importantes :",
        "Deutsch": "Allemand",
        "Französisch": "Français",
        "Italienisch": "Italien",
        "Englisch": "Anglais",
        "Spanisch": "Espagnol",
        "Portugiesisch": "Portugais",
        "Kurse": "Cours",
        "Impersonation beenden": "Arrêter l'usurpation",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "Désinscrire réellement le participant au cours ?",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "Souhaitez-vous vraiment retirer le/la participant(e) du cours ?",
        "Instructors": "Responsables de cours",
        "Teachers": "Moniteurs de conduite",
        "Kursleiterinnen": "Responsables de cours",
        "The {attribute} must contain at least one letter.": "{attribute} doit être composé d'au moins un caractère.",
        "The {attribute} must contain at least one number.": "{attribute} doit être composé d'au moins un chiffre.",
        "The {attribute} must contain at least one symbol.": "{attribute} doit être composé d'au moins un caractère spécial.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} doit être composé d'au moins une lettre majuscule et une lettre minuscule.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Le {attribute} est déjà apparu dans une fuite de données. Veuillez choisir un autre {attribute}.",
        "INSURANCE_FLAT": "Forfait administratif",
        "lessons": "Leçons de conduite",
        "vku": "Cours VKU",
        "insurance": "Assurance",
        "app": "Application théorique",
        "voice_status_start": "Lancement",
        "voice_status_end": "Terminé",
        "voice_status_queue": "File d'attente",
        "voice_status_taken": "en discussion",
        "voice_status_missed": "Manqué",
        "absences": "Absence",
        "exams": "Examens",
        "comments": "Commentaires",
        "employment": "Emploi",
        "course_documents": "Documents de cours",
        "course_manuals": "Instructions de cours",
        "All": "Tous les",
        "Teilnehmende abmelden": "Désinscription des participants",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Le fichier {fileName} a été importé {statusHint}.",
        "Adresse": "Adresse",
        "Kursübersicht": "Aperçu des cours",
        "Zurück zum Login": "Retour à la connexion",
        "Passwort bestätigen": "Confirmer le mot de passe",
        "Dashboard": "Tableau de bord",
        "In welchen Kurs soll {participantName} verschoben werden?": "Dans quel cours {participantName} doit-il être déplacé ?",
        "Es gibt keine anderen Kurse.": "Il n'y a pas d'autres cours.",
        "Mehr anzeigen": "Voir plus",
        "{participantName} in Kurs {courseName} verschieben?": "Déplacer {participantName} vers le cours {courseName} ?",
        "Zum Kurs": "Vers le cours",
        "Umbuchungsbestätigung senden": "Envoyer une confirmation de changement de réservation",
        "Zurück": "Retour",
        "Verschieben": "Déplacer",
        "Willkommen {companyName} 👋": "Bienvenue {companyName} 👋",
        "Abbrechen": "Annuler",
        "Ähnliche TN": "TN similaires",
        "erfolgreich": "avec succès",
        "Abmelden": "Se désinscrire",
        "certificates.not-yet-known": "Pas encore connu",
        "certificates.sanio": "Confirmation de cours Sanio",
        "certificates.src": "Certificat SRC",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Si vous utilisez Excel pour l'importation, veillez à ce que les colonnes telles que la date de naissance soient formatées en tant que texte.",
        "Fehlermeldung": "Message d'erreur",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Les lignes suivantes n'ont pas pu être importées en raison de données non valables (<span class=\"text-red\">marqué en rouge</span>) :",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Les lignes suivantes ont tout de même été importées en raison d'une attribution peu claire.",
        "Handynummer": "Numéro de téléphone portable",
        "Kontaktdaten": "Coordonnées",
        "Kurs wechseln": "Changer de cours",
        "locations": "Sites",
        "Mitarbeitende": "Collaborateurs",
        "Spalte": "Colonne",
        "students": "Étudiants",
        "Teilnehmerübersicht öffnen": "Ouvrir l'aperçu des participants",
        "teilweise": "en partie",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_voicemail": "Message vocal",
        "{amount} Teilnehmende": "{amount} Participants",
        "Zeile": "Ligne",
        "Email": "E-mail",
        "voice_status_initiated": "Bienvenue",
        "errors.4xx.title": "Erreur inattendue",
        "Diese Anmeldemaske wurde deaktiviert.": "Ce masque de connexion a été désactivé.",
        "errors.4xx.head": "Oops...",
        "errors.4xx.hint": "Une erreur s'est produite. Votre demande n'a pas pu être traitée. Veuillez vérifier vos saisies ou réessayer ultérieurement. L'erreur nous a été signalée.",
        "errors.5xx.title": "Erreur de serveur interne",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Une erreur inattendue s'est produite. Retournez à la page d'accueil ou réessayez plus tard. L'erreur nous a été signalée.",
        "errors.401.title": "Accès refusé",
        "errors.401.head": "Oups…",
        "errors.401.hint": "Vous n'êtes pas autorisé(e) à accéder à cette page. Veuillez vous connecter et réessayer.",
        "errors.403.title": "Accès refusé",
        "errors.403.head": "Oups…",
        "errors.403.hint": "L'accès est refusé. Vous n'avez pas l'autorisation de visiter cette page. Veuillez nous contacter si vous pensez qu'il s'agit d'une erreur.",
        "errors.404.title": "Page non trouvée",
        "errors.404.head": "Oops...",
        "errors.404.hint": "La page que vous avez consultée n'existe pas. Revenez en arrière ou cliquez sur le bouton pour revenir à la page d'accueil.",
        "errors.500.title": "Erreur de serveur interne",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Une erreur inattendue s'est produite. Retournez à la page d'accueil ou réessayez plus tard. L'erreur nous a été signalée.",
        "errors.503.title": "Mode de maintenance",
        "errors.503.head": "Un instant, s'il vous plaît...",
        "errors.503.hint": "Nous effectuons actuellement des travaux de maintenance afin d'apporter des améliorations et de corriger des bugs pour une meilleure expérience utilisateur. Nous vous invitons à réessayer prochainement. Nous vous remercions de votre compréhension.",
        "errors.503.hint2": "En cas d'urgence, vous pouvez nous contacter par e-mail à <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> ou par téléphone à <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Délai d'attente",
        "errors.504.head": "Temps mort…",
        "errors.504.hint": "La demande a pris trop de temps. Veuillez réessayer plus tard. L'erreur nous a été signalée.",
        "errors.back-to-start": "Vers la page d'accueil",
        "{attribute} is keine gültige Sprache.": "{attribute} n'est pas une langue valide.",
        "Fehler beim Laden der Kurse": "Erreur lors du chargement des cours",
        "Die aktuellen Infos lauten:": "Voici les nouvelles informations :",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "C'est bientôt l'heure du cours \"{courseName}\".",
        "Hier nochmals die wichtigsten Infos:": "Voici encore une fois les informations les plus importantes :",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Tu t'es inscrit avec succès au cours \"{courseName}\" 🎉",
        "Kurs-ID": "ID du cours",
        "Zum Kalender hinzufügen": "Ajouter au calendrier",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Aucune adresse électronique ou numéro de téléphone portable n'a été indiqué.",
        "Die E-Mail-Adresse ist ungültig.": "L'adresse e-mail n'est pas valide",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Tu as été transféré dans le cours \"{courseName}\".",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Si tu as des questions, contacte directement {name} à l'adresse suivante",
        "oder": "ou",
        "Kursort:": "Lieu du cours :",
        "Kursdaten": "Dates des cours",
        "Die Handynummer ist ungültig.": "Le numéro de téléphone portable n'est pas valide.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Vous ne pouvez malheureusement pas vous inscrire avec cet e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Vous ne pouvez malheureusement pas vous inscrire avec ce numéro de téléphone.",
        "Der Code ist ungültig.": "Le code n'est pas valide.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Vous devez accepter les conditions générales pour pouvoir continuer.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Veuillez saisir un numéro de téléphone portable valide.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Merci beaucoup pour ton inscription au cours. Voici encore une fois les informations les plus importantes :",
        "Der Katalog wurde erstellt.": "Le catalogue a été créé.",
        "Der Katalog wurde aktualisiert.": "Le catalogue a été mis à jour.",
        "Der Katalog wurde entfernt.": "Le catalogue a été supprimé.",
        "Das Thema wurde aktualisiert.": "Le sujet a été mis à jour.",
        "Das Thema wurde entfernt.": "Le sujet a été supprimé.",
        "Der Kurs wurde dupliziert 🎉": "Le cours a été dupliqué 🎉",
        "Kurs": "Cours",
        "Text wurde in die Zwischenablage kopiert": "Le texte a été copié dans le presse-papiers",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Le texte n'a pas pu être copié dans le presse-papiers",
        "Das Thema wurde erstellt.": "Le sujet a été créé.",
        "Story wurde erfolgreich kopiert 🎉": "L'histoire a été copiée avec succès 🎉",
        "hier": "ici",
        "Name": "Nom",
        "Eine Variante|{count} Varianten": "Une variante|{count} Variantes",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "L'espace eLearning n'est pas disponible pour ce client.",
        "Geschlecht": "Sexe",
        "Männlich": "masculin",
        "Weiblich": "féminin",
        "Divers": "autre",
        "Projektstandort": "Lieu du projet",
        "Wohnkanton": "Canton de résidence",
        "Sanio Logo": "Logo Sanio",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{$unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Tu ne veux plus recevoir d'offres de notre part ? Tu peux te désinscrire sur <a href=\"{$unsubscribeUrl}\" target=\"_blank\">ici</a>.",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Cliquez ici pour accéder à nos conditions générales de vente <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\"></a> et à notre déclaration de confidentialité <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\"></a> .",
        "Hallo {name} 👋": "Salut {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Voici à nouveau les principaux détails :",
        "Teilnehmer:innen": "Participant(e)s",
        "Letzter Kurs": "Dernier cours",
        "Nächster Kurs": "Prochain cours",
        "Hinzufügen": "Ajouter",
        "Exportieren": "Exporter",
        "An Kurs angemeldet": "Inscrit à un cours",
        "Keinem Kurs zugeordnet": "Non affecté à un cours",
        "Suchbegriff": "Recherche de mots-clés",
        "Dein Sanio Team": "Votre équipe Sanio",
        "Alle": "Tous les",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Tu ne veux plus recevoir d'offres de notre part ? Tu peux te désinscrire sur <a href=\"{unsubscribeUrl}\" target=\"_blank\">ici</a>."
    },
    "it": {
        "Anmelden": "Iscrizione",
        "E-Mail": "e-mail",
        "Passwort": "Password",
        "Passwort vergessen?": "Avete dimenticato la password?",
        "Eine Woche lang angemeldet bleiben": "Rimanete connessi per una settimana",
        "Willkommen bei": "Benvenuti a",
        "Passwort zurücksetzen": "Reimpostare la password",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Riceverete un link via e-mail per reimpostare la password. Inserire l'indirizzo e-mail utilizzato per accedere a My Sanio.",
        "Zurück zur Anmeldung": "Torna all'accesso",
        "Link anfordern": "Link per la richiesta",
        "zurück": "indietro",
        "Liste exportieren": "Elenco di esportazione",
        "Anmeldungen": "Registrazioni",
        "Abmeldungen": "Cancellazioni",
        "Vorname": "Nome",
        "Nachname": "Cognome",
        "Kurssprache": "Lingua del corso",
        "Beschreibung": "Descrizione",
        "Link ist aktiv": "Il collegamento è attivo",
        "Kurs freigeben": "Condividi il corso",
        "Es existieren keine Daten": "Non ci sono dati esistenti.",
        "Willkommen": "Benvenuti",
        "Teilnehmende": "Partecipanti",
        "Sortieren nach": "Ordina per",
        "Datum": "Data",
        "Adresse": "Indirizzo",
        "Gesamte Liste freigeben": "Condividi l'intero elenco",
        "Kursgruppe freigeben": "Condividere il gruppo di corsi",
        "Plätze frei": "Slot rimanenti",
        "ausgebucht": "al completo",
        "Vielen Dank! Wir haben Ihre Angaben erhalten": "Grazie mille! Abbiamo ricevuto i vostri dati.",
        "Weitere Person anmelden": "Registrazione di una persona aggiuntiva",
        "An-/Abmeldung": "Registrazione/annullamento",
        "Ja, ich nehme teil": "Sì, partecipo",
        "Nein, ich bin verhindert": "No, non posso partecipare",
        "Ich akzeptiere die": "Accetto il",
        "allgemeinen Geschäftsbedingungen": "Termini e condizioni",
        "Die": "Il",
        "Datenschutzerklärung": "Informativa sulla privacy",
        "habe ich gelesen und verstanden": "Ho letto e compreso.",
        "Absenden": "Inviare",
        "Sie müssen die AGB akzeptieren!": "Dovete accettare le condizioni!",
        "Fragen oder Probleme?": "Domande o problemi?",
        "Ihre Ansprechperson:": "Il vostro interlocutore:",
        "Geburtsdatum": "Data di nascita",
        "Hallo": "Ciao",
        "Anmeldebestätigung": "Conferma dell'iscrizione",
        "Erste Hilfe Kurs": "Corso di Primo Soccorso",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti",
        "Liebe Grüsse": "Cordiali saluti",
        "Dein Sanio-Team": "Il tuo Team Sanio",
        "Copyright": "Diritti d'autore",
        "Umbuchungsbestätigung": "Conferma di modifica della prenotazione",
        "Terminerinnerung": "Promemoria appuntamento",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Il momento è arrivato e il corso di primo soccorso si terrà presto. Ecco ancora una volta le informazioni più importanti:",
        "Deutsch": "Tedesco",
        "Französisch": "Francese",
        "Italienisch": "Italiano",
        "Englisch": "Inglese",
        "Spanisch": "Spagnolo",
        "Portugiesisch": "Portoghese",
        "Kurse": "Corsi",
        "Impersonation beenden": "Termina l'impersonificazione",
        "course_manuals": "Istruzioni per il corso",
        "employment": "Occupazione",
        "habe ich gelesen und verstanden.": "Ho letto e compreso.",
        "Instructors": "Istruttori",
        "insurance": "Assicurazione",
        "INSURANCE_FLAT": "Tassa di amministrazione",
        "Kontaktdaten": "Dettagli di contatto",
        "Kurs wechseln": "Cambiamento di rotta",
        "Kursleiterinnen": "Istruttori",
        "lessons": "Lezioni di guida",
        "locations": "Luoghi",
        "Mitarbeitende": "I dipendenti",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "Si vuole davvero cancellare il partecipante dal corso?",
        "Kursübersicht": "Panoramica del corso",
        "Zurück zum Login": "Torna al login",
        "Passwort bestätigen": "Confermare la password",
        "Dashboard": "Cruscotto",
        "In welchen Kurs soll {participantName} verschoben werden?": "In quale corso dovrebbe essere spostato {participantName}?",
        "Es gibt keine anderen Kurse.": "Non ci sono altri corsi.",
        "Mehr anzeigen": "Mostra di più",
        "{participantName} in Kurs {courseName} verschieben?": "Spostare {participantName} al corso {courseName}?",
        "Zum Kurs": "Al corso",
        "Umbuchungsbestätigung senden": "Inviare la conferma della prenotazione",
        "Zurück": "Indietro",
        "Verschieben": "Muoversi",
        "Willkommen {companyName} 👋": "Benvenuto {companyName} 👋",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "State davvero cancellando il partecipante dal corso?",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Il file {fileName} è stato importato {statusHint}.",
        "erfolgreich": "Successo",
        "exams": "Esami",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Se si utilizza Excel per l'importazione, assicurarsi che colonne come la data di nascita siano formattate come testo.",
        "Fehlermeldung": "Messaggio di errore",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "<span class=\"text-red\"></span>Le righe seguenti non possono essere importate a causa di dati non validi (contrassegnati in rosso):",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Le righe seguenti sono state comunque importate a causa di un'assegnazione non chiara.",
        "Handynummer": "Numero di cellulare",
        "app": "Applicazione teorica",
        "absences": "Assenze",
        "comments": "Commenti",
        "course_documents": "Documenti del corso",
        "All": "Tutti",
        "Spalte": "Colonna",
        "students": "Alunni",
        "Teachers": "Istruttori di guida",
        "Teilnehmende abmelden": "Cancellazione dei partecipanti",
        "Teilnehmerübersicht öffnen": "Panoramica dei partecipanti aperti",
        "teilweise": "in parte",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "L'{attribute} è già apparso in una fuga di dati. Selezionare un altro {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} deve essere composto da almeno un carattere.",
        "The {attribute} must contain at least one number.": "{attribute} deve essere composto da almeno un numero.",
        "The {attribute} must contain at least one symbol.": "{attribute} deve essere composto da almeno un carattere speciale.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} deve essere composto da almeno una lettera maiuscola e una minuscola.",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Grazie mille! Abbiamo ricevuto i vostri dati.",
        "vku": "Corsi VKU",
        "voice_status_end": "Finito",
        "voice_status_missed": "Mancato",
        "voice_status_out-of-office": "Fuori ufficio",
        "voice_status_queue": "Coda",
        "voice_status_start": "Inizio",
        "voice_status_taken": "in dialogo",
        "voice_status_voicemail": "Messaggio vocale",
        "{amount} Teilnehmende": "{amount} partecipanti",
        "Zeile": "Linea",
        "Ähnliche TN": "TN simile",
        "Es existieren keine Daten.": "Non esistono dati.",
        "Abbrechen": "Annullamento",
        "Abmelden": "Disconnettersi",
        "certificates.not-yet-known": "Non ancora noto",
        "certificates.sanio": "Conferma del corso Sanio",
        "certificates.src": "Certificato SRC",
        "Email": "e-mail",
        "voice_status_initiated": "Benvenuti",
        "Diese Anmeldemaske wurde deaktiviert.": "Questa schermata di accesso è stata disattivata.",
        "errors.4xx.title": "Errore imprevisto",
        "errors.4xx.head": "Ops...",
        "errors.4xx.hint": "Si è verificato un errore. Non è stato possibile elaborare la richiesta. Si prega di controllare i dati inseriti o di riprovare più tardi. L'errore è stato segnalato.",
        "errors.5xx.title": "Errore interno del server",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Si è verificato un errore imprevisto. Tornare alla pagina iniziale o riprovare più tardi. L'errore è stato segnalato.",
        "errors.401.title": "Accesso negato",
        "errors.401.head": "Ops…",
        "errors.401.hint": "Non sei autorizzato ad accedere a questa pagina. Effettuare il login e riprovare.",
        "errors.403.title": "Accesso negato",
        "errors.403.head": "Ops…",
        "errors.403.hint": "Accesso negato. Non siete autorizzati a visitare questa pagina. Se ritenete che si tratti di un errore, contattateci.",
        "errors.404.title": "Pagina non trovata",
        "errors.404.head": "Ops...",
        "errors.404.hint": "La pagina richiamata non esiste. Tornare indietro o fare clic sul pulsante per tornare alla pagina iniziale.",
        "errors.500.title": "Errore interno del server",
        "errors.500.head": "Ou ou...",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "La richiesta è durata troppo a lungo. Si prega di riprovare più tardi. L'errore è stato segnalato.",
        "errors.back-to-start": "Alla pagina iniziale",
        "errors.500.hint": "Si è verificato un errore imprevisto. Tornare alla pagina iniziale o riprovare più tardi. L'errore è stato segnalato.",
        "errors.503.title": "Modalità di manutenzione",
        "errors.503.head": "Un momento per favore...",
        "errors.503.hint": "Stiamo effettuando una manutenzione per apportare miglioramenti e correzioni di bug per migliorare l'esperienza dell'utente. Si prega di riprovare a breve. Grazie per la vostra comprensione.",
        "errors.503.hint2": "<a href=\"mailto:mail@sanio.ch\"></a> <a href=\"tel:+41315212409\"></a>In caso di urgenza, potete contattarci via e-mail all'indirizzo mail@sanio.ch o telefonicamente al numero 031 521 24 09 .",
        "errors.504.title": "Timeout",
        "{attribute} is keine gültige Sprache.": "{attribute} non è un linguaggio valido.",
        "Fehler beim Laden der Kurse": "Errore nel caricamento dei corsi",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Siete stati riprenotati al corso \"{courseName}\".",
        "Die aktuellen Infos lauten:": "Ecco le nuove informazioni:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Per qualsiasi domanda, si prega di contattare direttamente {name}:",
        "oder": "o",
        "Kursort:": "Sede del corso:",
        "Kursdaten": "Date del corso",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Presto arriverà il momento in cui si terrà il corso \"{courseName}\".",
        "Kurs-ID": "ID corso",
        "Hier nochmals die wichtigsten Infos:": "Ecco di nuovo le informazioni più importanti:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "L'iscrizione al corso \"{courseName}\" è stata effettuata con successo 🎉",
        "Zum Kalender hinzufügen": "Aggiungi al calendario",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Non è stato fornito alcun indirizzo e-mail o numero di cellulare.",
        "Die E-Mail-Adresse ist ungültig.": "L'indirizzo e-mail non è valido",
        "Die Handynummer ist ungültig.": "Il numero di cellulare non è valido.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questa e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questo numero di telefono.",
        "Der Code ist ungültig.": "Il codice non è valido.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Per continuare è necessario accettare i termini e le condizioni.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Inserire un numero di cellulare valido.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti",
        "Der Kurs wurde dupliziert 🎉": "Il corso è stato duplicato 🎉",
        "Kurs": "Corso",
        "Text wurde in die Zwischenablage kopiert": "Il testo è stato copiato negli appunti",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Non è stato possibile copiare il testo negli appunti",
        "Der Katalog wurde erstellt.": "È stato creato il catalogo.",
        "Der Katalog wurde aktualisiert.": "Il catalogo è stato aggiornato.",
        "Der Katalog wurde entfernt.": "Il catalogo è stato rimosso.",
        "Das Thema wurde erstellt.": "L'argomento è stato creato.",
        "Das Thema wurde aktualisiert.": "L'argomento è stato aggiornato.",
        "Das Thema wurde entfernt.": "L'argomento è stato rimosso.",
        "Story wurde erfolgreich kopiert 🎉": "La storia è stata copiata con successo 🎉",
        "Geschlecht": "Genere",
        "Eine Variante|{count} Varianten": "Una variante|{count} Varianti",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "L'area eLearning non è disponibile per questo cliente.",
        "Männlich": "maschile",
        "Weiblich": "femminile",
        "Divers": "altra definizione di genere",
        "Projektstandort": "Sede del progetto",
        "Wohnkanton": "Cantone di residenza",
        "Sanio Logo": "Logo Sanio",
        "hier": "qui",
        "Hallo {name} 👋": "Ciao {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Ecco di nuovo i dettagli più importanti:",
        "Teilnehmer:innen": "Partecipanti",
        "Name": "Nome",
        "Letzter Kurs": "Ultimo corso",
        "Nächster Kurs": "Il prossimo corso",
        "Hinzufügen": "Aggiungi",
        "Exportieren": "Esportazione",
        "An Kurs angemeldet": "Iscrizione a un corso",
        "Keinem Kurs zugeordnet": "Non assegnato a un corso",
        "Suchbegriff": "Termine di ricerca",
        "Dein Sanio Team": "Il tuo Team Sanio",
        "Alle": "Tutti",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Qui trovi i nostri <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">termini e condizioni generali</a> e la nostra <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">informativa sulla privacy</a>.",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Se non desideri più ricevere e-mail da questa lista, puoi annullare l'iscrizione <a href=\"{unsubscribeListUrl}\" target=\"_blank\">qui</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Non vuoi più ricevere offerte da parte nostra? Puoi annullare l'iscrizione <a href=\"{unsubscribeUrl}\" target=\"_blank\">qui</a>."
    },
    "pt": {
        "Anmelden": "Registo",
        "E-Mail": "correio eletrónico",
        "Passwort": "Palavra-passe",
        "Passwort vergessen?": "Esqueceu-se da sua palavra-passe?",
        "Eine Woche lang angemeldet bleiben": "Manter a sessão iniciada durante uma semana",
        "Willkommen bei": "Bem-vindo ao",
        "Passwort zurücksetzen": "Repor a palavra-passe",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Receberá uma ligação por correio eletrónico para redefinir a sua palavra-passe. Introduza o endereço de correio eletrónico que utilizou para iniciar sessão em A Minha Sanio.",
        "Zurück zur Anmeldung": "Voltar a iniciar sessão",
        "Link anfordern": "Pedir ligação",
        "zurück": "voltar",
        "Liste exportieren": "Lista de exportação",
        "Anmeldungen": "Registos",
        "Abmeldungen": "Cancelamentos",
        "Vorname": "Nome próprio",
        "Nachname": "Apelido",
        "Kurssprache": "Língua do curso",
        "Beschreibung": "Descrição",
        "Link ist aktiv": "A ligação está ativa",
        "Kurs freigeben": "Curso de partilha",
        "Es existieren keine Daten": "Não existem dados actuais.",
        "Willkommen": "Bem-vindo",
        "Teilnehmende": "Participantes",
        "Sortieren nach": "Ordenar por",
        "Datum": "Data",
        "Adresse": "Endereço",
        "Gesamte Liste freigeben": "Partilhar toda a lista",
        "Kursgruppe freigeben": "Partilhar grupo de disciplinas",
        "Plätze frei": "Slots restantes",
        "ausgebucht": "totalmente reservado",
        "Vielen Dank! Wir haben Ihre Angaben erhalten": "Muito obrigado! Recebemos os seus dados.",
        "Weitere Person anmelden": "Registar uma pessoa adicional",
        "An-/Abmeldung": "Registo/cancelamento",
        "Ja, ich nehme teil": "Sim, participarei",
        "Nein, ich bin verhindert": "Não, não posso estar presente",
        "Ich akzeptiere die": "Aceito a",
        "allgemeinen Geschäftsbedingungen": "Termos e condições",
        "Die": "O",
        "Datenschutzerklärung": "Política de privacidade",
        "habe ich gelesen und verstanden": "Li e compreendi.",
        "Absenden": "Enviar",
        "Sie müssen die AGB akzeptieren!": "Tem de aceitar as condições!",
        "Fragen oder Probleme?": "Perguntas ou problemas?",
        "Ihre Ansprechperson:": "A sua pessoa de contacto:",
        "Geburtsdatum": "Data de nascimento",
        "Hallo": "Olá",
        "Anmeldebestätigung": "Confirmação de Inscrição",
        "Erste Hilfe Kurs": "Curso de Primeiros Socorros",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Obrigado pela sua inscrição no Curso de Primeiros Socorros. Aqui estão novamente as informações mais importantes",
        "Liebe Grüsse": "Atenciosamente",
        "Dein Sanio-Team": "Sua equipe Sanio",
        "Copyright": "Direitos Autorais",
        "Umbuchungsbestätigung": "Confirmação de alteração de reserva",
        "Terminerinnerung": "Lembrete de compromisso",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Logo chegará o momento e o curso de primeiros socorros acontecerá em breve. Aqui estão novamente as informações mais importantes:",
        "Deutsch": "Alemão",
        "Französisch": "Francês",
        "Italienisch": "Italiano",
        "Englisch": "Inglês",
        "Spanisch": "Espanhol",
        "Portugiesisch": "Português",
        "Kurse": "Cursos",
        "Impersonation beenden": "Terminar a personificação",
        "Spalte": "Coluna",
        "Kurs wechseln": "Mudar de curso",
        "Den/die Teilnehmende wirklich vom Kurs abmelden?": "Está realmente a cancelar a participação do participante no curso?",
        "Instructors": "Instrutores",
        "Kursübersicht": "Descrição geral do curso",
        "Zurück zum Login": "Voltar ao início de sessão",
        "Passwort bestätigen": "Confirmar a palavra-passe",
        "Dashboard": "Painel de controlo",
        "In welchen Kurs soll {participantName} verschoben werden?": "Para que curso deve {participantName} ser transferido?",
        "Es gibt keine anderen Kurse.": "Não existem outros cursos.",
        "Mehr anzeigen": "Mostrar mais",
        "{participantName} in Kurs {courseName} verschieben?": "Transferir {participantName} para o curso {courseName}?",
        "Zum Kurs": "Para o curso",
        "Umbuchungsbestätigung senden": "Enviar confirmação de nova reserva",
        "Möchten Sie den/die Teilnehmende wirklich vom Kurs abmelden?": "Pretende mesmo anular o registo do participante no curso?",
        "Zurück": "Voltar",
        "Verschieben": "Mover",
        "Willkommen {companyName} 👋": "Bem-vindo {companyName} 👋",
        "voice_status_end": "Acabado",
        "voice_status_queue": "Fila de espera",
        "voice_status_missed": "Faltou",
        "voice_status_out-of-office": "Fora do escritório",
        "exams": "Exames",
        "INSURANCE_FLAT": "Taxa de administração",
        "lessons": "Aulas de condução",
        "vku": "Cursos VKU",
        "insurance": "Seguros",
        "app": "Aplicação teórica",
        "voice_status_start": "Início",
        "absences": "Ausências",
        "Abbrechen": "Cancelar",
        "Abmelden": "Terminar sessão",
        "All": "Todos",
        "certificates.sanio": "Confirmação do curso Sanio",
        "certificates.src": "Certificado SRC",
        "comments": "Comentários",
        "course_documents": "Documentos do curso",
        "course_manuals": "Instruções do curso",
        "Die Datei {fileName} wurde {statusHint} importiert.": "O ficheiro {fileName} foi importado {statusHint}.",
        "employment": "Emprego",
        "erfolgreich": "Bem-sucedido",
        "Es existieren keine Daten.": "Não existem dados.",
        "students": "Alunos",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Se utilizar o Excel para a importação, certifique-se de que colunas como a data de nascimento são formatadas como texto.",
        "Fehlermeldung": "Mensagem de erro",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "<span class=\"text-red\"></span>As seguintes linhas não puderam ser importadas devido a dados inválidos (marcados a vermelho):",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "No entanto, as linhas seguintes foram importadas devido a uma afetação pouco clara.",
        "habe ich gelesen und verstanden.": "Li e compreendi.",
        "Handynummer": "Número de telemóvel",
        "Kursleiterinnen": "Instrutores",
        "Kontaktdaten": "Dados de contacto",
        "locations": "Localizações",
        "Mitarbeitende": "Empregados",
        "certificates.not-yet-known": "Ainda não conhecido",
        "Teachers": "Instrutores de condução",
        "Teilnehmende abmelden": "Cancelamento de participantes",
        "Teilnehmerübersicht öffnen": "Síntese do participante aberto",
        "teilweise": "em parte",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "O {attribute} já apareceu numa fuga de dados. Por favor, seleccione outro {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} deve consistir em pelo menos um carácter.",
        "The {attribute} must contain at least one number.": "{attribute} deve consistir em pelo menos um número.",
        "The {attribute} must contain at least one symbol.": "{attribute} deve consistir em pelo menos um carácter especial.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} deve ser constituído por, pelo menos, uma letra maiúscula e uma letra minúscula.",
        "{amount} Teilnehmende": "{amount} Participantes",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Muito obrigado! Recebemos os seus dados.",
        "voice_status_taken": "em diálogo",
        "voice_status_voicemail": "Mensagem de voz",
        "Zeile": "Linha",
        "Ähnliche TN": "TN semelhante",
        "voice_status_initiated": "Bem-vindo",
        "Email": "correio eletrónico",
        "Diese Anmeldemaske wurde deaktiviert.": "Este ecrã de início de sessão foi desativado.",
        "errors.4xx.title": "Erro inesperado",
        "errors.4xx.head": "Ops...",
        "errors.4xx.hint": "Ocorreu um erro. O seu pedido não pôde ser processado. Por favor, verifique as suas entradas ou tente novamente mais tarde. O erro foi-nos comunicado.",
        "errors.5xx.title": "Erro interno do servidor",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Ocorreu um erro inesperado. Regressar à página inicial ou tentar novamente mais tarde. O erro foi-nos comunicado.",
        "errors.401.title": "Acesso negado",
        "errors.401.head": "Ops…",
        "errors.401.hint": "Não está autorizado a aceder a esta página. Inicie sessão e tente novamente.",
        "errors.403.head": "Ops…",
        "errors.403.hint": "Acesso negado. Não está autorizado a visitar esta página. Contacte-nos se considerar que se trata de um erro.",
        "errors.404.title": "Página não encontrada",
        "errors.404.head": "Ops...",
        "errors.404.hint": "A página que chamou não existe. Volte atrás ou clique no botão para regressar à página inicial.",
        "errors.500.title": "Erro interno do servidor",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Ocorreu um erro inesperado. Regressar à página inicial ou tentar novamente mais tarde. O erro foi-nos comunicado.",
        "errors.503.title": "Modo de manutenção",
        "errors.503.head": "Um momento, por favor...",
        "errors.503.hint": "Estamos atualmente a efetuar uma manutenção para introduzir melhorias e correcções de erros para uma melhor experiência do utilizador. Por favor, tente novamente em breve. Obrigado pela sua compreensão.",
        "errors.504.title": "Tempo limite",
        "errors.504.head": "Tempo limite…",
        "errors.403.title": "Acesso negado",
        "errors.503.hint2": "<a href=\"mailto:mail@sanio.ch\"></a> <a href=\"tel:+41315212409\"></a>Em caso de urgência, pode contactar-nos por correio eletrónico para mail@sanio.ch ou por telefone para 031 521 24 09 .",
        "errors.504.hint": "A consulta demorou demasiado tempo. Por favor, tente novamente mais tarde. O erro foi-nos comunicado.",
        "errors.back-to-start": "Para a página inicial",
        "{attribute} is keine gültige Sprache.": "{attribute} não é uma língua válida.",
        "Fehler beim Laden der Kurse": "Erro ao carregar os cursos",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Foi transferido para o curso \"{courseName}\".",
        "oder": "ou",
        "Die aktuellen Infos lauten:": "Eis as novas informações:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Se tiver alguma dúvida, contacte diretamente {name}:",
        "Kursort:": "Localização do curso:",
        "Kursdaten": "Datas dos cursos",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Em breve chegará o momento em que o curso \"{courseName}\" terá lugar.",
        "Hier nochmals die wichtigsten Infos:": "Eis novamente as informações mais importantes:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Foi inscrito com sucesso no curso \"{courseName}\" 🎉",
        "Kurs-ID": "ID do curso",
        "Zum Kalender hinzufügen": "Adicionar ao calendário",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Não foi fornecido qualquer endereço de correio eletrónico ou número de telemóvel.",
        "Die E-Mail-Adresse ist ungültig.": "O endereço de correio eletrónico é inválido",
        "Die Handynummer ist ungültig.": "O número de telemóvel é inválido.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Infelizmente, não é possível registar-se com este e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Infelizmente, não é possível registar-se com este número de telefone.",
        "Der Code ist ungültig.": "O código é inválido.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Para poder continuar, tem de aceitar as condições gerais.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Introduza um número de telemóvel válido.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Obrigado pela sua inscrição no Curso de Primeiros Socorros. Aqui estão novamente as informações mais importantes",
        "Text wurde in die Zwischenablage kopiert": "O texto foi copiado para a área de transferência",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Não foi possível copiar o texto para a área de transferência",
        "Der Katalog wurde erstellt.": "O catálogo foi criado.",
        "Der Katalog wurde aktualisiert.": "O catálogo foi atualizado.",
        "Der Katalog wurde entfernt.": "O catálogo foi retirado.",
        "Das Thema wurde erstellt.": "O tópico foi criado.",
        "Das Thema wurde aktualisiert.": "O tópico foi atualizado.",
        "Das Thema wurde entfernt.": "O tópico foi removido.",
        "Der Kurs wurde dupliziert 🎉": "O curso foi duplicado 🎉",
        "Kurs": "Curso",
        "Story wurde erfolgreich kopiert 🎉": "A história foi copiada com sucesso 🎉",
        "Eine Variante|{count} Varianten": "Uma variante|{count} Variantes",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "A área de eLearning não está disponível para este cliente.",
        "Geschlecht": "Género",
        "Männlich": "masculino",
        "Weiblich": "feminino",
        "Divers": "outro",
        "Projektstandort": "Localização do projeto",
        "Wohnkanton": "Cantão de residência",
        "Sanio Logo": "Logótipo Sanio",
        "hier": "aqui",
        "Hallo {name} 👋": "Olá {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Eis novamente os pormenores mais importantes:",
        "Teilnehmer:innen": "Participantes",
        "Name": "Nome",
        "Letzter Kurs": "Último curso",
        "Nächster Kurs": "Próximo curso",
        "Hinzufügen": "Adicionar",
        "Exportieren": "Exportação",
        "An Kurs angemeldet": "Inscrito num curso",
        "Keinem Kurs zugeordnet": "Não atribuído a um curso",
        "Suchbegriff": "Termo de pesquisa",
        "Dein Sanio Team": "Sua equipe Sanio",
        "Alle": "Todos",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Aqui estão os nossos <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">termos e condições gerais</a> e a nossa <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">política de privacidade</a>.",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Se não deseja mais receber e-mails desta lista, pode cancelar a sua inscrição <a href=\"{unsubscribeListUrl}\" target=\"_blank\">aqui</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Não quer mais receber ofertas nossas? Pode cancelar a sua inscrição <a href=\"{unsubscribeUrl}\" target=\"_blank\">aqui</a>."
    }
}
