import './bootstrap'
import '../css/app.css'

import { createI18n } from 'vue-i18n'
import localeMessages from './vue-i18n-locales.generated'
import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'

import store from '@/Stores/index'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import Vue3Toasity from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useSessionFlashMessage } from '@/Composables/useSessionFlashMessage'
import sentryPlugin from '@/plugins/sentry.js'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Sanio'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    delay: 250,
    color: '#43ABDF',
  },
  setup({ el, App, props, plugin }) {
    const i18n = createI18n({
      legacy: false,
      locale: props.initialPage.props.locale,
      fallbackLocale: 'de',
      messages: localeMessages,
    })

    const toastOptions = {
      position: 'bottom-right',
      autoClose: 6000,
      hideProgressBar: true,
      pauseOnFocusLoss: false,
      transition: 'slide',
      theme: 'colored',
      containerClassName: 'sanio-toast-container',
    }

    const app = createApp({ render: () => h(App, props) })

    return app
      .use(plugin)
      .use(store)
      .use(i18n)
      .use(Vue3Toasity, toastOptions)
      .use(sentryPlugin)
      .mixin({ methods: { route } })
      .mount(el)
  },
})

const { flashMessage } = useSessionFlashMessage()

router.on('success', () => {
  flashMessage()
})

router.on('invalid', () => {
  flashMessage()
})
